import React from 'react'

const NotFound = () => {
    return (
        <div className="Home">
            <h2>Sorry, but you're in wrong page :(</h2>
        </div>
    )   
}

export default NotFound;